/*** 1. Variables ***/

// 1.1 Font sizes:

$fontsize-text: calc(0.8rem + min(0.6vh, 0.3vw));       // General font size for texts, such as on the home screen
$fontsize-forms: calc(0.6rem + min(0.55vh, 0.3vw));      // Default font used in forms
$fontsize-label: calc(0.6rem + min(0.4vh, 0.25vw));      // Font size of form labels
$fontsize-small: calc(0.5rem + min(0.5vh, 0.3vw));      // Font size for small components

$fontsize-header-links: min(1.6vh, 1.2vw);


// 1.2 Colors:

$header-link-color: #333;
$header-link-color-selected: white;

$home-header-color: #8db8e3;
$home-header-color-start: #051d33;
$home-header-color-end: #4fb9c9;
$home-header-font-color: white;

$food-analyzer-panel-header: #b6c9f1;   // Background color of the tab bar of the food analyzer data panel
$food-analyzer-panel-area: #f2f5fd;     // Background color of the food analyzer data panel
$food-analyzer-panel-border: $food-analyzer-panel-area;   // Border color if at least one tab is open
$food-analyzer-panel-empty: $food-analyzer-panel-area;    // Background color of the data panel surrounding if no tab is open

$help-modal-color: #f6eed5;
$modal-background-color-1: #eff2f6;
$modal-background-color-2: #dee5ed;

// 1.3 Food Panel sizes:

// width of the whole panel area (basis for further calculations)
$food-analyzer-panel-width: calc(300px + 40vw);

$food-analyzer-panel-height: calc(#{$food-analyzer-panel-width} * 0.48);     // height of the whole panel area
$food-analyzer-chart-height: calc(#{$food-analyzer-panel-width} * 0.38);    // height of the chart area within a tab (including a config line)

// height of the chart/table area without a config line
$food-analyzer-chart-height-without-configline: calc(#{$food-analyzer-panel-width} * 0.45);

$food-analyzer-chart-height-dc: calc(35vh);                 // height of the chart area in the direct compare view


/*** 2. Mobile Device Main Settings ***/

// Force Rotation to Landscape mode on mobile devices
/* @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
    html {
        transform: rotate(-90deg);
        transform-origin: left top;
        width: 100vh;
        height: 100vw;
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        left: 0;
    }
} */


/*** 3. Bootstrap Overwrites ***/

.btn {
    font-size: $fontsize-forms !important;
}

.btn-link {
    outline: none !important;
    box-shadow: none !important;
}

.form-text {
    font-size: $fontsize-text !important;
}

.form-control-sm {
    font-size: $fontsize-forms !important;
    padding: 0 !important;
}

.form-label {
    color: #999999;
    font-weight: bolder;
    font-size: $fontsize-label !important;
}

.form-label-dc-selector {
    color: #666666;
    font-weight: bold;
    font-size: $fontsize-label !important;
    text-align: center;
}

.form-radiobutton {
    font-size: $fontsize-forms !important;
    margin-right: 1.2vw;
}


/*** 4. Global Form Styles ***/

.form-horizontal-separation {
    padding-right: 2vw;
}

.form-section {
    padding-bottom: 1.0vh;
}

.form-section-small {
    padding-bottom: 0.2vh !important;
}

.form-button {
    min-width: 15ch;
    margin-left: 2ch;
}

.form-button-sm {
    min-width: 10ch;
    margin-left: 1.5ch;
}

.form-elements {
    margin-right: 0.75vw;
}

.form-elements-m {
    margin-right: 0.5vw;
}

.form-elements-largespace {
     margin-right: 50px;
 }

.form-input {
    margin-right: 5px;
}

.form-row {
    padding-bottom: 20px;
}

.form-row-m {
    padding-bottom: 10px;
}

.form-row-indent {
    margin-left: 1.3vw;
    padding-bottom: 1vh;
}

.form-row-indent-m {
    margin-left: 0.8vw;
    padding-bottom: 0.6vh;
}

.button-apply {
    font-size: $fontsize-forms;
}

.button-displaymode, .button-displaymode-enabled, .button-displaymode-disabled {
    color: white;
}

.button-displaymode-enabled {
    font-size: $fontsize-label !important;
    background: #bf0f17;
}

.button-displaymode-disabled {
    font-size: $fontsize-label !important;
    background: rgba(191, 15, 23, 0.5);
}

.button-foodPanelHead, .button-closeTab {
    font-size: $fontsize-label !important;
    margin-right: 0.5vw;
}

.container-scroll {
    width: 100%;
    overflow-x: auto;
    margin-left: auto;
    margin-right: auto;
}

.chart-control-button-bar {
    padding-top: 32px;
}

.text-small {
    font-size: $fontsize-small;
}



/*** 5. Custom Form Styles ***/

.contact [type="radio"] {
    display: none;
}



/*** 6. Header ***/

.header {
    background: $home-header-color;
    height: 9.5vh;
}

.header-m {
    background: $home-header-color;
    height: max(9.5vh, 9.5vw);
}

.header-form {
    font-size: $fontsize-small;
}

.header-form-label {
    margin-right: 0.8vw;
    color: black;
    font-weight: bold;
}

.header-form-label-m {
    margin-right: 1.5vw;
    color: black;
}

@mixin header-label-base {
           borderBottom: 1px solid #BBBBBB;
           paddingLeft: 0.4vw;
           paddingRight: 0.4vw;
       }

.header-label {
    @include  header-label-base;
    font-size: min(1.2vw, 2.0vh);
}

.header-label-small {
    @include header-label-base;
    font-size: min(1vw, 1.8vh);
}

.header-link {
    padding-right: 1.2vw !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    font-size: $fontsize-header-links !important;
    color: $header-link-color !important;
}

.header-link-mobile {
    font-size: $fontsize-forms;
    padding-right: 0.5vw !important;
    color:$header-link-color !important;
}

// Remove the annoying border/shadow when the header link has the focus:
.header-link.focus, .header-link:focus, .header-link.active, .header-link:active, .header-link:active:focus, .header-link.active:focus, .header-link.active.focus {
    outline: none !important;
    box-shadow: none !important;
    color: $header-link-color-selected !important;
    text-decoration: underline;
}

.header-link-mobile .focus, .header-link-mobile:focus, .header-link-mobile.active, .header-link-mobile:active, .header-link-mobile:active:focus, .header-link-mobile.active:focus, .header-link-mobile.active.focus {
    outline: none !important;
    box-shadow: none !important;
    color: $header-link-color-selected !important;
    text-decoration: underline;
}

.header-logo {
    object-fit: contain;
    height: 100%;
}

.header-logo-m {
    height: 8vh;
    width: 8vh;
}

.header-logo-text {
    height: 4vh;
}



/*** 7. Home Menu ***/

.home {
    padding-top: 1vw;
    padding-left: 2.5vw;
    font-size: $fontsize-text;
}

.home-header {
    background: linear-gradient(to right, $home-header-color-start, $home-header-color-end);
    height: max(32vh, 13vw);
    font-size: min( calc(0.4rem + 1.2vh), calc(0.4rem + 0.6vw));
    color: $home-header-font-color;
}

.home-header-mobile {
    background: linear-gradient(to right, $home-header-color-start, $home-header-color-end);
    height: max(28vh, 18vw);
    font-size: min( calc(0.6rem + 1.2vh), calc(0.6rem + 0.8vw));
    color: $home-header-font-color;
}

.home-header-slogan {
    font-size: min( calc(0.7rem + 1.2vh), calc(0.7rem + 0.8vw));
    font-style: italic;
    color: #ffca2c;
}

.home-header-carousel .slide img {
    height: max(26vh, 11.5vw) !important;
    object-fit: contain;
}

.home-startbutton {
    height: min(30vh, 18vw);
    width: min(30vh, 18vw);
    margin: 3vw;
}

.home-startbutton-mobile {
    height: min(35vh, 30vw);
    width: min(35vh, 30vw);
    margin: 3vw;
}

.home-startbutton-label {
    height: 50%;
    padding: 0.5vw;
    font-size: min( calc(0.4rem + 1.2vh), calc(0.4rem + 0.8vw));
}

.mobile-device-message {
    position: absolute;
    padding: 20px;
    margin: 0px;
    width: 100%;
    height: 100%;
    background: #ffffff;
    z-index: 100;
}


/*** 8. Food Analyzer / Direct Compare Menu ***/

.chart-area {
    height: $food-analyzer-chart-height;
}

.chart-area-without-configline {
    height: $food-analyzer-chart-height-without-configline;
}

.chart-area-dc {
    height: $food-analyzer-chart-height-dc;
}

@mixin foodanalyzer-button {
    width: min(7vh, 3.5vw);
    height: min(7vh, 3.5vw);
}

.foodanalyzer-button-normal {
    @include foodanalyzer-button;
    margin-bottom: 1.5vh;
}

.foodanalyzer-button-separator {
    @include foodanalyzer-button;
    margin-bottom: 3.5vh;
}

.foodanalyzer-buttonbar {
    padding: 0.5vw;
    margin-right: 2.4vw;
}

@mixin foodanalyzer-basepanel {
    padding: min(3vh, 6vw);
    border-radius: min(0.75vh, 1.5vw);
}

.foodanalyzer-basepanel {
    @include foodanalyzer-basepanel;
    background:  #fff;
}

.foodanalyzer-basepanel-empty {
    @include foodanalyzer-basepanel;
    border-width: min(0.2vw, 0.1vh);
    border-color: #aaa;
    border-style: solid;
    background: $food-analyzer-panel-empty;
}

// MAIN CSS CLASS TO DEFINE THE WIDTH OF THE FOOD ANALYZER CONTAINER
.foodanalyzer-inner-container {
    max-width: $food-analyzer-panel-width;
    min-width: $food-analyzer-panel-width;
    margin-top: -1vh;
}

.food-analyzer-sourcebox {
    font-size: $fontsize-label;
}

.food-analyzer-chartpanel {
    max-height: $food-analyzer-panel-height;
    min-height: $food-analyzer-panel-height;
}

.sidebar-link {
    margin-top: 0.5vw;
    min-width: 4vw;
    color: #969696 !important;
}

.sidebar-link.focus, .sidebar-link:focus, .sidebar-link.active, .sidebar-link:active, .sidebar-link:active:focus, .sidebar-link.active:focus, .sidebar-link.active.focus {
    outline: none !important;
    box-shadow: none !important;
}

.sidebar-link.active {
    color: #0064ff !important;
    text-decoration: underline;
}

.legend-container {
    min-width: 18ch;
    margin-left: 1ch;
}

.legend-item {
    min-width: min(1.0vw, 1.5vh);
    max-width: min(1.0vw, 1.5vh);
    min-height: min(1.0vw, 1.5vh);
    max-height: min(1.0vw, 1.5vh);
    margin-top: 0.6vh;
    margin-bottom: 0.6vh;
    margin-right: 1ch;
    border: 1px solid black;
}

.legend-item-label {
    font-size: $fontsize-label;
}

.legend-item-label-mobile {
    font-size: max(1.2vw, 1.2vh);
}

.direct-compare-panel {
    height: 60vh;
}

.table-style {
    font-size: $fontsize-label;
}

.table-style-m {
    font-size: $fontsize-label;
}

.table-data {
    font-size: $fontsize-label;
    height: $food-analyzer-chart-height-without-configline;
    overflow-y: auto;
    margin: 2vh;
}

.juxtaposition-data {
    font-size: $fontsize-label;
    height: $food-analyzer-chart-height;
    overflow-y: auto;
    margin: 2vh;
}

.vertical-label {
    display:flex;
    justify-content: center;
    padding-left: 5px;
    padding-right: 10px;
    font-weight: bold;
    writing-mode: vertical-rl;
    text-align: center;
    text-orientation: mixed;
}

.vertical-label-small {
    display:flex;
    justify-content:center;
    padding-left: 5px;
    padding-right: -5px;
    writing-mode: vertical-rl;
    text-align: center;
    text-orientation: mixed;
    font-size: $fontsize-small;
}

.mainPanel {
    background: $food-analyzer-panel-area;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    padding-top: 4px;
}

.tab-panel {
    padding: 5px 24px !important;
    background: $food-analyzer-panel-header !important;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom: 1px solid black !important;
}

.tab-panel button.tab {
    border: 1px solid black !important;
    font-weight: normal;
    background: #333 !important;
}

.tab-panel button.tab.active {
    background: #006ab9 !important;
    color: white;
    font-weight: bold;
}

.tab-content {
    padding: 0px !important;
}

.table-header-no-top-border {
    display: none !important;
}

.tab-info {
    font-size: $fontsize-label;
    height: $food-analyzer-chart-height-without-configline;
    overflow-y: auto;
    padding: 1vh;
}

.tab-info-directcompare {
    font-size: $fontsize-label;
    height: $food-analyzer-chart-height-dc;
    overflow-y: auto;
    padding: 3vh;
}



/*** 9. User Settings ***/

.usersettings-form {
    max-width: 80ch;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    padding: 3vh;
}

.usersettings-pal {
    width: 100%;
    padding-top: 3vh;
}

.usersettings-pal-text {
    width: 100%;
    font-size: $fontsize-label;
    padding-top: 0.75vh;
    color: grey;
}



/*** 10. Ranking ***/

.ranking-chart {
    overflow-x: auto;
    position: absolute;
    max-width: 70vw;
    background-color: $food-analyzer-panel-area;
    padding: 1vw;
}

.ranking-chart-m {
    overflow-x: auto;
    position: absolute;
    background-color: $food-analyzer-panel-area;
    padding: 1vw;
}


/*** 11. Overall View ***/

.overallview-table {
    overflow-x: scroll;

    &table {
        table-layout: fixed;
        width: 100%;
        padding-bottom: 10vh;
    }

    &table, th, td {
        border: solid 1px;
        font-size: $fontsize-forms;
    }
}


.toolTipTableCell {
    position: relative;
}

@mixin toolTipCellContent {
    display: none;
    position: absolute;
    background-color: #fdebd0;
    border: 1px solid;
    padding: 1.5ch;
    font-style: normal;
    font-size: $fontsize-forms;
    font-weight: normal;
    z-index: 100;
}

.toolTipCellContentHeader {
    @include toolTipCellContent;
    min-width: 10vw;
    top: 5vh;
    left: 3vw;
}

.toolTipCellContentTable {
    @include toolTipCellContent;
    min-width: 18vw;
    left: 3vw;
}

.toolTipTableCell:hover span.toolTipCellContentHeader {
    display: block;
}

.toolTipTableCell:hover span.toolTipCellContentTable {
    display: block;
}

.tooltipKeyWord {
    display: inline-block;
    padding-left: 3ch;
    font-weight: bold;
    min-width: 20ch;
}


/*** 12. Modals ***/

// Help modal may appear as a modal in a modal, therefore it gets a custom layout (different color and shifted position)
.help-modal .modal-content {
    background-color: $help-modal-color;
    border: 1px solid black;
    top: 3.5vh !important;
    left: 5vw !important;
}

.unit-modal .modal-content {
    background-color: $modal-background-color-1;
    border: 1px solid black;
    top: 5vh !important;
}

.filter-modal .modal-content {
    background-color: $modal-background-color-1;
    border: 1px solid black;
    top: 8vh !important;
}

.filter-selection-modal .modal-content {
    background-color: $modal-background-color-2;
    border: 1px solid black;
    top: 15vh !important;
}
